globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"gq3nMWL5AZEHTlK6z1Lf6"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const ENVIRONMENT = process.env.NODE_ENV || 'development';
const SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://3f3890e406354a458007e2e7f3d6b2eb@glitch.carketa.app/4';
const isDev = ENVIRONMENT === 'development';

Sentry.init({
  debug: isDev,
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 0.1,
  includeLocalVariables: true,
  ignoreErrors: ["reading 'portalId'", "evaluating 'e.portalId'"],
  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
