import { useContext, createContext, useEffect } from 'react';
import { useMe } from '../../services/user';
import { Session } from 'next-auth';

import type { inferProcedureOutput } from '@trpc/server';
import type { AppRouter } from '../../server/trpc/router';
import type { OrganizationSettings } from 'src/types/organizations/organizations';
import { trpc } from 'src/utils/trpc';
import { OrganizationSetupChecklist } from 'src/types/organizations/organizations';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

export type Me = inferProcedureOutput<AppRouter['users']['getMe']>;

type ContextProps = {
  me: Me | null | undefined;
  session: Session | null;
  callFlutterHandler: (handler: string, payload: any) => void;
  isFlutter: boolean;
  hasOrganizationSetting: (setting: keyof OrganizationSettings) => boolean;
  getOrganizationSettings: () => OrganizationSettings;
  updateOrganizationSettings: (
    setting_key: keyof OrganizationSettings,
    setting_value: any
  ) => void;
  getChecklistItems: () => OrganizationSetupChecklist;
  updateChecklistItems: (
    checklist_item_key: string,
    checklist_item_value: boolean | number
  ) => void;
  showReconIcons: boolean;
  toggleReconIcons: () => void;
};

const AppContext = createContext<ContextProps | null>(null);

type ProviderProps = {
  children?: React.ReactNode;
};

const AppProvider: React.FC<ProviderProps> = ({ children }) => {
  const { me, session, refetch } = useMe();
  let isFlutter = false;
  const isDevelopment = process.env.NODE_ENV === 'development';
  const NEXT_PUBLIC_AMPLITUDE_API_KEY =
    process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

  if (typeof window !== 'undefined') {
    isFlutter = Boolean(window.flutter_inappwebview);
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && me?.email && !isDevelopment) {
      // Amplitude
      if (window.amplitude) {
        const identifyEvent = new window.amplitude.Identify();
        identifyEvent.set('organization_name', me?.organization?.name);
        identifyEvent.set('organization_id', me?.organization?.id);
        identifyEvent.set(
          'organization_feature_flags',
          me?.organization?.feature_flags
        );
        window.amplitude.identify(identifyEvent);
        window.amplitude.init('ffdf6d4b415ae4c35ab3abb6cdd478dd', me.email, {
          defaultTracking: true,
        });
      }
      // UserGuiding
      if (window.userGuiding) {
        window.userGuiding?.identify(me?.id, {
          email: me?.email,
          name: me?.first_name + ' ' + me?.last_name,
          created_at: new Date().getTime(),
          company: {
            id: me?.organization?.id,
            name: me?.organization?.name,
            created_at: new Date().getTime(),
          },
        });
      }
    }
  }, [
    isDevelopment,
    me?.id,
    me?.organization?.name,
    me?.email,
    me?.organization?.feature_flags,
    me?.organization?.id,
    me?.first_name,
    me?.last_name,
  ]);

  const callFlutterHandler = async (handler: string, payload: any) => {
    if (window.flutter_inappwebview) {
      return window.flutter_inappwebview.callHandler(handler, payload);
    }
  };

  const updateSettings = trpc.organizations.updateSettings.useMutation({
    onError(error) {
      console.error(error);
    },
    onSuccess() {
      refetch();
    },
  });

  const updateChecklist = trpc.organizations.updateChecklistItems.useMutation({
    onError(error) {
      console.error(error);
    },
    onSuccess() {
      refetch();
    },
  });

  const getOrganizationSettings = () => {
    const organization_settings = me?.organization
      ?.settings as OrganizationSettings;
    return organization_settings;
  };

  const getChecklistItems = () => {
    const organization_setup_checklist_item = me?.organization
      ?.setup_checklist_items as OrganizationSetupChecklist;
    return organization_setup_checklist_item;
  };

  const hasOrganizationSetting = (setting: keyof OrganizationSettings) => {
    const organization_settings = getOrganizationSettings();
    return organization_settings[setting] === true;
  };

  const updateOrganizationSettings = (
    setting_key: keyof OrganizationSettings,
    setting_value: any
  ) => {
    updateSettings.mutate({ setting_key, setting_value });
  };

  const updateChecklistItems = (
    checklist_item_key: string,
    checklist_item_value: boolean | number
  ) => {
    updateChecklist.mutate({ checklist_item_key, checklist_item_value });
  };

  useEffect(() => {
    if (me?.id && window.flutter_inappwebview) {
      const syncDeviceToUser = async () => {
        callFlutterHandler('get-device', {});
        callFlutterHandler('userTypeHandler', { userType: me?.type });

        const org_feature_flags =
          (me?.organization?.feature_flags as string[]) ?? []; //This is how you get feature flags for the org
        callFlutterHandler('set-products', { org_feature_flags }); // This would call a set-products function in flutter
      };
      syncDeviceToUser();
    }
  }, [me?.id]);

  const [showReconIcons, setShowReconIcons] = useLocalStorage(
    'showReconIcons',
    false
  );
  const toggleReconIcons = () => {
    setShowReconIcons(!showReconIcons);
  };

  return (
    <AppContext.Provider
      value={{
        me,
        session,
        callFlutterHandler,
        isFlutter,
        hasOrganizationSetting,
        getOrganizationSettings,
        getChecklistItems,
        updateOrganizationSettings,
        updateChecklistItems,
        showReconIcons,
        toggleReconIcons,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext) as ContextProps;

export { AppContext, AppProvider };
